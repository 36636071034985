import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from '../../styles/components/TabNavigation.module.scss';
import classnames from 'classnames';
import { MobileContext } from '../../context/mobileContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import Lottie from 'lottie-react';
import animations from '../../assets/animations/animations';

export default function TabNavigation({ data }) {
  const [activeTab, setActiveTab] = useState(null);
  const [active, setActive] = useState(false);
  const elemHeight = useRef();
  const isMobile = useContext(MobileContext);
  const wrapperRef = useRef(null);
  const onScreen = useIntersectionObserver(wrapperRef);
  const tabName = useRef(null);

  const interactive = {
    mode: 'scroll',
    actions: [
      {
        visibility: [0, 0.1],
        type: 'play',
      },
      {
        visibility: [0.1],
        type: 'stop',
      },
    ],
  };

  useEffect(() => {
    setActiveTab(data?.tabs[0].name);
  }, [data]);

  const executeScroll = () =>
    tabName.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const tabsMobile = (
    <div className={styles.tabContentWrapper}>
      {data.tabs.map((item, index) => {
        return (
          activeTab === item.name && (
            <div className={classnames(styles.tabContent)} key={index}>
              {item.modules?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classnames(
                      styles.tabModule,
                      item.assetPosition === 'right' ? styles.reverse : ''
                    )}
                  >
                    <div className={styles.content}>
                      <h2>{item.name}</h2>
                      <div
                        className={styles.text}
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      ></div>
                      {item.ctaURL &&
                        (item.external ? (
                          <a
                            className={classnames(
                              styles.button,
                              styles[item.ctaStyle]
                            )}
                            href={item.ctaURL}
                            target={item.newTab ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {item.ctaText}
                          </a>
                        ) : (
                          <a
                            className={classnames(
                              styles.button,
                              styles[item.ctaStyle]
                            )}
                            href={`#${item.ctaURL}`}
                          >
                            {item.ctaText}
                          </a>
                        ))}
                      {item.downloadTitle && item.downloads && (
                        <div className={styles.downloadWrapper}>
                          <p
                            className={classnames(
                              styles.downloadBtn,
                              active ? styles.transform : null
                            )}
                            onClick={() => setActive(!active)}
                          >
                            {item.downloadTitle}
                          </p>
                          <div
                            className={classnames(
                              styles.downloadsContainer,
                              active ? styles.show : null
                            )}
                            ref={elemHeight}
                            style={
                              active
                                ? {
                                    maxHeight:
                                      elemHeight.current?.scrollHeight + 'px',
                                  }
                                : { maxHeight: '0px' }
                            }
                          >
                            {item.downloads.map((download, idx) => {
                              return (
                                <div className={styles.download} key={idx}>
                                  <a
                                    href={download.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {download.title}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.image}>
                      {item.assetType === 'lottie' ? (
                        <Lottie
                          animationData={animations[item.asset].title}
                          interactivity={interactive}
                        />
                      ) : item.assetType === 'video' ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `
                                <video
                                  muted
                                  autoplay
                                  playsinline
                                  src="${item.asset}"   
                                />`,
                          }}
                        ></div>
                      ) : (
                        <img src={item.asset} alt={item.assetAlt} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )
        );
      })}
    </div>
  );

  const tabsDesktop = (
    <div className={styles.tabContentWrapper}>
      {data.tabs.map((item, index) => {
        return (
          activeTab === item.name && (
            <div className={classnames(styles.tabContent)} key={index}>
              {item.modules?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={classnames(
                      styles.tabModule,
                      item.assetPosition === 'right' ? styles.reverse : ''
                    )}
                  >
                    <div className={styles.content}>
                      <h2>{item.name}</h2>
                      <div
                        className={styles.text}
                        dangerouslySetInnerHTML={{
                          __html: item.text,
                        }}
                      ></div>
                      {item.ctaURL &&
                        (item.external ? (
                          <a
                            className={classnames(
                              styles.button,
                              styles[item.ctaStyle]
                            )}
                            href={item.ctaURL}
                            target={item.newTab ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {item.ctaText}
                          </a>
                        ) : (
                          <a
                            className={classnames(
                              styles.button,
                              styles[item.ctaStyle]
                            )}
                            href={`#${item.ctaURL}`}
                          >
                            {item.ctaText}
                          </a>
                        ))}
                      {item.downloadTitle && item.downloads && (
                        <div className={styles.downloadWrapper}>
                          <p
                            className={classnames(
                              styles.downloadBtn,
                              active ? styles.transform : null
                            )}
                            onClick={() => setActive(!active)}
                          >
                            {item.downloadTitle}
                          </p>
                          <div
                            className={classnames(
                              styles.downloadsContainer,
                              active ? styles.show : null
                            )}
                            ref={elemHeight}
                            style={
                              active
                                ? {
                                    maxHeight:
                                      elemHeight.current?.scrollHeight + 'px',
                                  }
                                : { maxHeight: '0px' }
                            }
                          >
                            {item.downloads.map((download, idx) => {
                              return (
                                <div className={styles.download} key={idx}>
                                  <a
                                    href={download.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {download.title}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.image}>
                      {item.assetType === 'lottie' ? (
                        <Lottie
                          animationData={animations[item.asset].title}
                          interactivity={interactive}
                        />
                      ) : item.assetType === 'video' ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `
                                <video
                                  muted
                                  autoplay
                                  playsinline
                                  src="${item.asset}"   
                                />`,
                          }}
                        ></div>
                      ) : (
                        <img src={item.asset} alt={item.assetAlt} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )
        );
      })}
    </div>
  );

  return (
    <div
      className={classnames(
        styles.tabNavigationWrapper,
        styles[data.style],
        onScreen?.isIntersecting ? styles.visible : ''
      )}
      id={data.anchorId}
      ref={wrapperRef}
    >
      <div className={styles.tabNameWrapper} ref={tabName}>
        {data.tabs.map((item, index) => {
          return (
            <div className={styles.tabNameMobileWrapper} key={index}>
              <button
                className={classnames(
                  styles.tabName,
                  activeTab === item.name ? styles.active : ''
                )}
                onClick={() => {
                  setActiveTab(item.name);
                  isMobile && executeScroll();
                }}
              >
                {item.name}
              </button>
              {isMobile && activeTab === item.name ? tabsMobile : null}
            </div>
          );
        })}
      </div>
      {!isMobile && tabsDesktop}
    </div>
  );
}
