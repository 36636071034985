export const data = {
  title: 'Second Page Title',
  metaTitle: 'Second Page Meta Title',
  metaDescription: 'Second Page Meta Description',
  metaImage: '/images/meta-image.jpeg', // should be absolute url
  components: [
    {
      type: 'video-stage',
      id: 0,
      style: 'secondDefault',
      anchorId: 'unsere-erfahrung',
      title: 'Meeting. Seminar. Tagung.',
      subtitle: 'Perfekte Räumlichkeiten mieten bei DB Training',
      overlayColor: 'rgb(255,255,255)',
      overlayOpacity: 0.2,
      overlayImage: '/images/video-second.png',
      overlayImageM: '/images/video-second-mobile.png',
      thumbnail: '/images/thumbnail-2.png',
      source: '/videos/video-second.mp4',
    },
    {
      type: 'columns',
      id: 1,
      style: 'secondDefault',
      anchorId: 'unsere-angebot',
      title: 'Wir sind vor Ort',
      subtitle:
        'Deutschlandweit stehen Ihnen in unseren verkehrsgünstig gelegenen Trainingszentren Seminarräume verschiedener Größen für eigene Meetings, Seminare oder Tagungen zur Verfügung.',
      items: [
        {
          icon: '/images/column-second-1.png',
          value: 15,
          title: 'Trainingszentren in ganz Deutschland',
        },
        {
          icon: '/images/column-second-2.png',
          value: 265,
          title: 'Top ausgestattete Trainingsräume',
        },
        {
          icon: '/images/column-second-3.png',
          value: 15000,
          title: 'Raumbuchungen pro Jahr',
        },
      ],
    },
    {
      type: 'module-title',
      id: 2,
      style: 'secondDefault',
      title: 'Räume zum\r\nWohlfühlen',
      anchorId: 'unsere-raume',
      subtitle:
        'Neben offen gestalteten Kommunikationsbereichen bieten unsere Trainingszentren zahlreiche Möglichkeiten der flexiblen Raumgestaltung.  Modernes Raumdesign und eine hochwertige, technische Ausstattung  bieten ideale Voraussetzungen für zeitgemäßes Lernen und Arbeiten.​',
      decoration: '/images/title-bg-second.png',
      decorationMob: '/images/title-bg-second-mob.png',
      backgroundColor: '#f5f5f5',
    },
    {
      type: 'gallery',
      id: 3,
      style: 'grey',
      images: [
        {
          id: 0,
          url: '/images/gallery-1.png',
          alt: 'gallery',
        },
        {
          id: 1,
          url: '/images/gallery-2.png',
          alt: 'gallery',
        },
        {
          id: 2,
          url: '/images/gallery-3.png',
          alt: 'gallery',
        },
        {
          id: 3,
          url: '/images/gallery-4.png',
          alt: 'gallery',
        },
        {
          id: 4,
          url: '/images/gallery-5.png',
          alt: 'gallery',
        },
        {
          id: 5,
          url: '/images/gallery-6.png',
          alt: 'gallery',
        },
        {
          id: 6,
          url: '/images/gallery-7.png',
          alt: 'gallery',
        },
        {
          id: 7,
          url: '/images/gallery-8.png',
          alt: 'gallery',
        },
        {
          id: 8,
          url: '/images/gallery-8.png',
          alt: 'gallery',
        },
        {
          id: 9,
          url: '/images/gallery-7.png',
          alt: 'gallery',
        },
        {
          id: 10,
          url: '/images/gallery-6.png',
          alt: 'gallery',
        },
        {
          id: 11,
          url: '/images/gallery-5.png',
          alt: 'gallery',
        },
        {
          id: 12,
          url: '/images/gallery-4.png',
          alt: 'gallery',
        },
        {
          id: 13,
          url: '/images/gallery-3.png',
          alt: 'gallery',
        },
        {
          id: 14,
          url: '/images/gallery-2.png',
          alt: 'gallery',
        },
        {
          id: 15,
          url: '/images/gallery-1.png',
          alt: 'gallery',
        },
      ],
    },
    {
      type: 'module-title',
      id: 4,
      style: 'secondDefault',
      title: 'Lorem ipsum\r\ndolor sit amen',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penati​',
      decoration: '/images/title-bg-second.png',
      decorationMob: '/images/title-bg-second-mob.png',
    },
    {
      type: 'tab-navigation',
      id: 5,
      style: 'secondDefault',
      tabs: [
        {
          name: 'Region Süd',
          modules: [
            {
              name: 'Karlsruhe',
              text: '<p>In unserem neuen Trainings- und Ausbildungszentrum in Köln-Dellbrück stehen Ihnen 25 moderne Seminarräume, ein Simulator, eine gewerblich-technische Ausbildungs-werkstatt sowie die Innovations-werkstatt für die Methode Design Thinking für Azubis zur Verfügung.​</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-1.svg',
              assetType: 'image',
              assetAlt: 'stuff',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: false,
              newTab: false,
            },
            {
              name: 'Ludwigsburg',
              text: '<p>Unser Trainingszentrum Duisburg befindet sich nur 5 Gehminuten vom Hauptbahnhof entfernt.​ Dort stehen Ihnen 6 moderne Seminarräume zur Verfügung.</p>',
              assetPosition: 'right',
              asset: '/videos/tabs-animation-2.mp4',
              assetType: 'video',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'Nürnberg',
              text: '<p>Unser Trainingszentrum Witten befindet sich 15 Gehminuten vom Hauptbahnhof entfernt. Dort steht Ihnen derzeit 1 Seminarraum zur Verfügung.</p>',
              assetPosition: 'left',
              asset: '0',
              assetType: 'lottie',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
          ],
        },
        {
          name: 'Region West',
          modules: [
            {
              name: 'KarlsruheW',
              text: '<p>In unserem neuen Trainings- und Ausbildungszentrum in Köln-Dellbrück stehen Ihnen 25 moderne Seminarräume, ein Simulator, eine gewerblich-technische Ausbildungs-werkstatt sowie die Innovations-werkstatt für die Methode Design Thinking für Azubis zur Verfügung.​</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-1.svg',
              assetType: 'image',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'LudwigsburgW',
              text: '<p>Unser Trainingszentrum Duisburg befindet sich nur 5 Gehminuten vom Hauptbahnhof entfernt.​ Dort stehen Ihnen 6 moderne Seminarräume zur Verfügung.</p>',
              assetPosition: 'right',
              asset: '/videos/tabs-animation-2.mp4',
              assetType: 'video',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'NürnbergW',
              text: '<p>Unser Trainingszentrum Witten befindet sich 15 Gehminuten vom Hauptbahnhof entfernt. Dort steht Ihnen derzeit 1 Seminarraum zur Verfügung.</p>',
              assetPosition: 'left',
              asset: '1',
              assetType: 'lottie',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
          ],
        },
        {
          name: 'Region Mitte',
          modules: [
            {
              name: 'KarlsruheM',
              text: '<p>In unserem neuen Trainings- und Ausbildungszentrum in Köln-Dellbrück stehen Ihnen 25 moderne Seminarräume, ein Simulator, eine gewerblich-technische Ausbildungs-werkstatt sowie die Innovations-werkstatt für die Methode Design Thinking für Azubis zur Verfügung.​</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-1.svg',
              assetType: 'image',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'LudwigsburgM',
              text: '<p>Unser Trainingszentrum Duisburg befindet sich nur 5 Gehminuten vom Hauptbahnhof entfernt.​ Dort stehen Ihnen 6 moderne Seminarräume zur Verfügung.</p>',
              assetPosition: 'right',
              asset: '/videos/tabs-animation-2.mp4',
              assetType: 'video',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'NürnbergM',
              text: '<p>Unser Trainingszentrum Witten befindet sich 15 Gehminuten vom Hauptbahnhof entfernt. Dort steht Ihnen derzeit 1 Seminarraum zur Verfügung.</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-3.svg',
              assetType: 'image',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
          ],
        },
        {
          name: 'Region Nord',
          modules: [
            {
              name: 'KarlsruheN',
              text: '<p>In unserem neuen Trainings- und Ausbildungszentrum in Köln-Dellbrück stehen Ihnen 25 moderne Seminarräume, ein Simulator, eine gewerblich-technische Ausbildungs-werkstatt sowie die Innovations-werkstatt für die Methode Design Thinking für Azubis zur Verfügung.​</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-1.svg',
              assetType: 'image',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'LudwigsburgN',
              text: '<p>Unser Trainingszentrum Duisburg befindet sich nur 5 Gehminuten vom Hauptbahnhof entfernt.​ Dort stehen Ihnen 6 moderne Seminarräume zur Verfügung.</p>',
              assetPosition: 'right',
              asset: '/videos/tabs-animation-2.mp4',
              assetType: 'video',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'NürnbergN',
              text: '<p>Unser Trainingszentrum Witten befindet sich 15 Gehminuten vom Hauptbahnhof entfernt. Dort steht Ihnen derzeit 1 Seminarraum zur Verfügung.</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-3.svg',
              assetType: 'image',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
          ],
        },
        {
          name: 'Region Ost',
          modules: [
            {
              name: 'KarlsruheO',
              text: '<p>In unserem neuen Trainings- und Ausbildungszentrum in Köln-Dellbrück stehen Ihnen 25 moderne Seminarräume, ein Simulator, eine gewerblich-technische Ausbildungs-werkstatt sowie die Innovations-werkstatt für die Methode Design Thinking für Azubis zur Verfügung.​</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-1.svg',
              assetType: 'image',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'LudwigsburgO',
              text: '<p>Unser Trainingszentrum Duisburg befindet sich nur 5 Gehminuten vom Hauptbahnhof entfernt.​ Dort stehen Ihnen 6 moderne Seminarräume zur Verfügung.</p>',
              assetPosition: 'right',
              asset: '/videos/tabs-animation-2.mp4',
              assetType: 'video',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
            {
              name: 'NürnbergO',
              text: '<p>Unser Trainingszentrum Witten befindet sich 15 Gehminuten vom Hauptbahnhof entfernt. Dort steht Ihnen derzeit 1 Seminarraum zur Verfügung.</p>',
              assetPosition: 'left',
              asset: '/images/second-tab-3.svg',
              assetType: 'image',
              ctaStyle: 'outline',
              ctaURL: 'https://www.google.com',
              ctaText: 'Jetzt buchen',
              external: true,
              newTab: true,
            },
          ],
        },
      ],
    },
    {
      type: 'module-title',
      id: 6,
      style: 'secondDefault',
      title: 'Unsere\r\nStandorte',
      anchorId: 'unsere-standorte',
      subtitle:
        'In unseren topausgestatteten 15 Trainingszentren und 30 Ausbildungswerkstätten bilden wir  Auszubildende in kaufmännisch-serviceorientierten, eisenbahnspezifischen und gewerblich-technischen Ausbildungsberufen aus.',
      decoration: '/images/title-bg-second.png',
      decorationMob: '/images/title-bg-second-mob.png',
      backgroundColor: '#f5f5f5',
    },
    {
      type: 'text-image-slider',
      id: 7,
      style: 'secondDefault',
      anchorId: 'image-slider',
      slides: [
        {
          image: '/images/carousel-image-2.png',
          imageMob: '/images/carousel-image-2.png',
          title: 'Neu in München',
          subtitle: '',
          copy: 'Barrierefrei, modern und gut gelegen: der zukünftige Standort des Ausbildungs- und Simulatorzentrums von DB Training in München-Perlach steht ab Herbst 2022 Ihren Auszubildenden zur Verfügung.',
          imagePosition: 'left',
          ctaStyle: 'outline',
          ctaURL: 'https://www.google.com',
          ctaText: 'Mehr erfahren',
          external: true,
          newTab: true,
        },
        {
          image: '/images/carousel-image-2.png',
          imageMob: '/images/carousel-image-2.png',
          title: 'Neu in München2',
          subtitle: '',
          copy: 'Barrierefrei, modern und gut gelegen: der zukünftige Standort des Ausbildungs- und Simulatorzentrums von DB Training in München-Perlach steht ab Herbst 2022 Ihren Auszubildenden zur Verfügung.',
          imagePosition: 'right',
          ctaStyle: 'outline',
          ctaURL: 'https://www.google.com',
          ctaText: 'Mehr erfahren',
          external: true,
          newTab: true,
        },
      ],
    },
    {
      type: 'interactive-map',
      id: 8,
      style: 'group-filters',
      locations: [
        {
          categories: ['Region Nord', 'bis 24', 'Parkplatz', 'Smartboard'],
          name: 'Trainingszentrum Dortmund',
          address: 'Werkmeisterstrase 65 44145 Dortmund',
          phone: '+49 231 7294437',
          route: '/pdf/sample.pdf',
          email: 'servicemanagement@deutschebahn.com',
          text: 'Trainingszentrum Leipzig',
          url: 'https://www.google.com',
          urlText: 'google.com',
          external: true,
          newTab: true,
          lat: 51.52517,
          lng: 7.49719,
        },
        {
          categories: ['Region Ost', 'bis 18', 'Parkplatz', 'Smartboard'],
          name: 'Hamburg',
          address: 'Hamburg',
          phone: '+49 231 7294437',
          route: '/pdf/sample.pdf',
          email: 'servicemanagement@deutschebahn.com',
          text: 'Trainingszentrum Leipzig',
          url: 'https://www.google.com',
          urlText: 'google.com',
          external: true,
          newTab: true,
          lat: 53.55562,
          lng: 9.98745,
        },
        {
          categories: [
            'Region West',
            'bis 5',
            'Casino DB Gastronomie',
            'Parkplatz',
          ],
          name: 'Leipzig',
          address: 'Leipzig',
          phone: '+49 231 7294437',
          route: '/pdf/sample.pdf',
          email: 'servicemanagement@deutschebahn.com',
          text: 'Trainingszentrum Leipzig',
          url: 'https://www.google.com',
          urlText: 'google.com',
          external: true,
          newTab: true,
          lat: 51.3452,
          lng: 12.38594,
        },
        {
          categories: [
            'Region Mitte',
            'bis 100',
            'Erreichbarkeit DB',
            'Break out Zone',
          ],
          name: 'Berlin',
          address: 'Berlin',
          phone: '+49 231 7294437',
          route: '/pdf/sample.pdf',
          email: 'servicemanagement@deutschebahn.com',
          text: 'Trainingszentrum Leipzig',
          url: 'https://www.google.com',
          urlText: 'google.com',
          external: true,
          newTab: true,
          lat: 52.51604,
          lng: 13.37691,
        },
        {
          categories: [
            'Region Süd',
            'bis 12',
            'Übernachtungsmöglichkeiten in unmittelbarer Nähe',
            'Break out Zone',
          ],
          name: 'Frankfurt',
          address: 'Frankfurt',
          phone: '+49 231 7294437',
          route: '/pdf/sample.pdf',
          email: 'servicemanagement@deutschebahn.com',
          text: 'Trainingszentrum Leipzig',
          url: 'https://www.google.com',
          urlText: 'google.com',
          external: true,
          newTab: true,
          lat: 50.11208,
          lng: 8.68341,
        },
        {
          categories: [
            'Region Nord',
            'bis 199',
            'Erreichbarkeit DB',
            'Smartboard',
          ],
          name: 'Dusseldorf',
          address: 'Dusseldorf',
          phone: '+49 231 7294437',
          route: '/pdf/sample.pdf',
          email: 'servicemanagement@deutschebahn.com',
          text: 'Trainingszentrum Leipzig',
          url: '#kontakt',
          urlText: 'kontakt',
          external: false,
          newTab: false,
          lat: 51.21564,
          lng: 6.77661,
        },
      ],
      filterGroups: [
        {
          name: 'Region',
          type: 'OR',
          filters: [
            'Region Nord',
            'Region Ost',
            'Region West',
            'Region Mitte',
            'Region Süd',
          ],
        },
        {
          name: 'Anzahl Teilnehmer',
          type: 'OR',
          filters: [
            'bis 5',
            'bis 12',
            'bis 18',
            'bis 24',
            'bis 100',
            'bis 199',
          ],
        },
        {
          name: 'Sonstiges',
          type: 'AND',
          filters: [
            'Break out Zone',
            'Erreichbarkeit DB',
            'Parkplatz',
            'Casino DB Gastronomie',
            'Übernachtungsmöglichkeiten in unmittelbarer Nähe',
            'Smartboard',
          ],
        },
      ],
    },
    {
      type: 'module-title',
      id: 9,
      style: 'secondDefault',
      title: 'Was Sie schon immer\r\nwissen wollten',
      subtitle: '',
      decoration: '/images/title-bg-second.png',
      decorationMob: '/images/title-bg-second-mob.png',
      anchorId: '#kontakt',
    },
    {
      type: 'faq',
      id: 10,
      style: 'default',
      anchorId: 'faq-accordion',
      data: [
        {
          question: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
          answer:
            'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penati',
        },
        {
          question: '2Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
          answer:
            '2Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penati',
        },
        {
          question: '3Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
          answer:
            '3Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penati',
        },
        {
          question: '4Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
          answer:
            '4Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penati',
        },
        {
          question: '5Lorem ipsum dolor sit amet, consectetuer adipiscing elit',
          answer:
            '5Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penati',
        },
      ],
    },
    {
      type: 'module-title',
      id: 11,
      style: 'secondDefault',
      title: 'Kontakt\r\nDB Training',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penati',
      decoration: '/images/title-bg-second.png',
      decorationMob: '/images/title-bg-second-mob.png',
    },
    {
      type: 'contact-form',
      id: 12,
      style: 'secondDefault',
      anchorId: 'contact-form',
      regions: [
        {
          name: 'Bitte wählen Sie Ihren Standort.',
          subcategories: [],
        },
        {
          name: 'Allgemeine Anfrage',
          subcategories: ['Übernachtungen', 'Tagungen', 'Seminare'],
        },
        {
          name: 'Region Nord',
          subcategories: [
            'Hamburg Holstenplatz',
            'Hannover',
            'Hamburg Langenfeld',
            'Rostock',
          ],
        },
        {
          name: 'Region West',
          subcategories: ['Duisburg & Witten', 'Köln', 'Wuppertal'],
        },
        {
          name: 'Region Süd',
          subcategories: [
            'München-Laim',
            'Nürnberg',
            'Ludwigsburg',
            'Karlsruhe & Mannheim',
          ],
        },
        {
          name: 'Region Mitte',
          subcategories: ['Frankfurt', 'Fulda'],
        },
        {
          name: 'Region Ost',
          subcategories: ['Berlin', 'Leipzig', 'Delitzsch', 'Erfurt'],
        },
      ],
      apiURL: '/',
      ctaText: 'Senden',
    },
  ],
};
