import React, {useEffect, useState, useContext, useRef} from 'react';
import styles from '../../styles/components/Gallery.module.scss';
import classnames from 'classnames';
import {MobileContext} from '../../context/mobileContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import arrowPrev from '../../assets/images/prev_arrow.svg';
import arrowNext from '../../assets/images/next_arrow.svg';

export default function Gallery({data}) {
    const isMobile = useContext(MobileContext);
    const wrapperRef = useRef(null);
    const onScreen = useIntersectionObserver(wrapperRef);
    const [chunks, setChunks] = useState([]);
    const slider = useRef(null);
    const sliderPopup = useRef(null);
    const [popup, setPopup] = useState(null);
    const slide = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        beforeChange: (oldIndex, newIndex) => {
            setCurrentSlide(newIndex);
        }
    };
    const settingsPopup = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    useEffect(() => {
        if (popup !== null) {
            sliderPopup.current.slickGoTo(parseInt(popup));
            slide.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
            if (typeof window != 'undefined' && window.document) {
                document.body.style.overflow = 'hidden';
            }
        } else document.body.style.overflow = 'unset';
    }, [popup]);

    useEffect(() => {
        const tempArray = [];
        const chunkSize = 8;
        for (let i = 0; i < data.images.length; i += chunkSize) {
            tempArray.push(data.images.slice(i, i + chunkSize));
        }
        setChunks(tempArray);
    }, [data.images]);

    return (
        <div
            className={classnames(
                styles.galleryWrapper,
                styles[data.style],
                onScreen?.isIntersecting ? styles.visible : ''
            )}
            id={data.anchorId}
            ref={wrapperRef}
        >
            <div className={styles.contentWrapper}>
                <div
                    className={classnames(
                        styles.slider_arrows,
                        popup !== null ? styles.popupArrow : null
                    )}
                >
                    <button className={classnames(styles.slider_arrow)}
                            onClick={() => {
                                popup !== null
                                    ? sliderPopup?.current.slickPrev()
                                    : slider?.current?.slickPrev();
                            }}>
                        <img
                            src={arrowPrev}
                            alt="Zurück"

                        />
                    </button>
                    <button className={classnames(styles.slider_arrow)}
                            onClick={() => {
                                popup !== null
                                    ? sliderPopup?.current.slickNext()
                                    : slider?.current?.slickNext();
                            }}>
                        <img
                            src={arrowNext}
                            alt="Weiter"
                        />
                    </button>
                </div>
                {!isMobile && chunks && (
                    <div
                        className={classnames(styles.carouselWrapper, styles.desktop, popup ? styles.carouselWrapperInactive : null)}
                        ref={slide}
                    >
                        <Slider ref={slider} {...settings}>
                            {chunks.map((chunk, chunkIndex) => {
                                return (
                                    <React.Fragment key={chunkIndex}>
                                        <div className={styles.slideWrapper}>
                                            {chunk.map((item, index) => {
                                                console.log(chunkIndex + " > " + currentSlide);
                                                const isCurrentChunk = currentSlide === chunkIndex;
                                                const isTabbable = isCurrentChunk && !popup;
                                                return (
                                                    <a href="/#"
                                                       className={styles.image}
                                                       key={index}
                                                       onClick={(e) => {
                                                           e.preventDefault()
                                                           setPopup(item.id);
                                                       }}
                                                       tabIndex={isTabbable ? 0 : -1}
                                                       aria-hidden={isTabbable ? "false" : "true"}
                                                    >
                                                        <img
                                                            src={item.url}
                                                            alt={item.alt}
                                                        />
                                                    </a>
                                                );
                                            })}
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </Slider>
                    </div>
                )}
                {isMobile && (
                    <div className={classnames(styles.carouselWrapper, styles.mobile)}>
                        <Slider ref={slider} {...settings}>
                            {data.images.map((item, index) => {
                                return (
                                    <div className={styles.slideWrapper} key={index}>
                                        <img src={item.url} alt={item.alt}/>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                )}
                {popup !== null && !isMobile && (
                    <div className={styles.popup}>
                        <div
                            className={styles.overlay}
                            onClick={() => {
                                setPopup(null);
                            }}
                        >
                            <button
                                className={styles.closeBtn}
                                onClick={() => {
                                    setPopup(null);
                                }}
                            >
                                <span aria-hidden={"true"}>X</span>
                                <span className={styles.srOnly}>Popup schließen</span>
                            </button>
                        </div>
                        <div className={styles.content}>
                            <Slider ref={sliderPopup} {...settingsPopup}>
                                {data.images.map((item, index) => {
                                    return (
                                        <div className={styles.slideWrapper} key={index}>
                                            <img src={item.url} alt={item.alt}/>
                                        </div>
                                    );
                                })}
                            </Slider>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
