import React, {useContext, useRef, useState} from 'react';
import styles from '../../styles/components/VideoStage.module.scss';
import classnames from 'classnames';
import {MobileContext} from '../../context/mobileContext';
import PauseIcon from '../../assets/images/pause.svg';
import PlayIcon from '../../assets/images/play.svg';

export default function VideoStage({data}) {
    const isMobile = useContext(MobileContext);
    const videoRef = useRef(null);
    const [isVideoPaused, setIsVideoPaused] = useState(false);

    const handleVideoPauseToggle = () => {
        const video = videoRef.current;
        if (video) {
            if (video.paused) {
                video.play();
                setIsVideoPaused(false);
            } else {
                video.pause();
                setIsVideoPaused(true);
            }
        }
    };

    return (
        <div
            className={classnames(styles.videoStageWrapper, styles[data.style])}
            id={data.anchorId}
        >
            <div className={styles.imageWrapper}>
                <img
                    src={isMobile ? data.overlayImageM : data.overlayImage}
                    alt={""}
                />
            </div>
            <div className={styles.contentWrapper}>
                <h1>{data.title}</h1>
                <p>{data.subtitle}</p>
            </div>
            <div
                className={styles.videoWrapper}
                style={{background: data.overlayColor, opacity: data.overlayOpacity}}
            >
                <video
                    ref={videoRef}
                    poster={data.thumbnail}
                    src={data.source}
                    autoPlay
                    muted
                    loop
                    playsInline
                ></video>
            </div>
            <button
                className={styles.videoToggleButton}
                onClick={handleVideoPauseToggle}
                aria-label={isVideoPaused ? "Video fortsetzen" : "Video pausieren"}
            >
                {isVideoPaused ?
                    <img src={PlayIcon} alt={""}/> :
                    <img src={PauseIcon} alt={""}/>}
            </button>
        </div>
    );
}