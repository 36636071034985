import React, {useContext, useRef, useState, useEffect} from 'react';
import styles from '../../styles/components/TextImageSlider.module.scss';
import classnames from 'classnames';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import {MobileContext} from '../../context/mobileContext';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';

export default function TextImageSlider({data}) {
    const isMobile = useContext(MobileContext);
    const wrapperRef = useRef(null);
    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const onScreen = useIntersectionObserver(wrapperRef);

    const settings = {
        dots: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: currentSlide,
        afterChange: (index) => setCurrentSlide(index)
    };

    return (
        <div
            className={classnames(styles.textImageSliderWrapper, styles[data.style])}
            id={data.anchorId}
            ref={wrapperRef}
        >
            <div
                className={classnames(
                    styles.sliderWrapper,
                    onScreen?.isIntersecting ? styles.visible : ''
                )}
            >
                <Slider ref={sliderRef} {...settings}>
                    {data.slides.map((item, index) => {
                        return (
                            <div
                                key={index}
                                data-slide-index={index}
                            >
                                <div
                                    className={classnames(
                                        styles.slide,
                                        item.imagePosition === 'right' ? styles.reverse : ''
                                    )}
                                >
                                    {data.style === 'secondDefault' && isMobile ? (
                                        <div className={styles.title}>
                                            <h2>{item.title}</h2>
                                        </div>
                                    ) : null}
                                    <div className={styles.image}>
                                        <img
                                            src={isMobile ? item.imageMob : item.image}
                                            alt={isMobile ? item.imageMobAlt : item.imageAlt}
                                        />
                                    </div>
                                    <div className={styles.content}>
                                        {data.style === 'secondDefault' && isMobile ? null : (
                                            <h2>{item.title}</h2>
                                        )}
                                        {item.subtitle && (
                                            <div
                                                className={styles.subtitle}
                                                dangerouslySetInnerHTML={{
                                                    __html: item.subtitle,
                                                }}
                                            ></div>
                                        )}
                                        <div
                                            className={styles.text}
                                            dangerouslySetInnerHTML={{
                                                __html: item.copy,
                                            }}
                                        ></div>
                                        {item.ctaText && item.external ? (
                                            <a
                                                className={classnames(
                                                    styles.button,
                                                    styles[item.ctaStyle]
                                                )}
                                                href={item.ctaURL}
                                                target={item.newTab ? '_blank' : '_self'}
                                                rel="noreferrer"
                                                tabIndex={index === currentSlide ? "0" : "-1"}
                                            >
                                                {item.ctaText}
                                            </a>
                                        ) : item.ctaText ? (
                                            <a
                                                className={classnames(
                                                    styles.button,
                                                    styles[item.ctaStyle]
                                                )}
                                                href={`#${item.ctaURL}`}
                                                tabIndex={index === currentSlide ? "0" : "-1"}
                                            >
                                                {item.ctaText}
                                            </a>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
}